import { CellStyle, CellStyleFunc } from '@ag-grid-community/core';
import { isSameMonth, isSameWeek } from 'date-fns';
import { de } from 'date-fns/locale';

import { KpiBucketType } from '../../../domain/demandValidation/model';
import { errorColor, schaefflerColor, warningColor } from '../../../styles/colors';

/*
 * used to indicate frozen zone and replenishment lead time (borders)
 * for material type SP we add 2 months, for AP&OP we add 3 months to the current date to indicate frozen zone.
 * replenishment lead time border gets calculated by adding currentRLTSchaeffler to the current date.
 * it might occur that both of the borders are at the same month, this edge case needs to be clarified.
 */
export const getCellStyleFunc =
  (
    periodType: KpiBucketType,
    rltDateString?: string,
    frozenZoneDateString?: string,
  ): CellStyleFunc =>
  (params): CellStyle | undefined => {
    const { colId: isoDateFromHeader } = params.colDef;
    const startOfBucket = new Date(isoDateFromHeader as string);
    const today = new Date(Date.now());

    const frozenZoneDate = frozenZoneDateString ? new Date(frozenZoneDateString) : today;
    const rltDate = rltDateString ? new Date(rltDateString) : today;

    let dateIsInBucket;
    switch (periodType) {
      case 'WEEK': {
        dateIsInBucket = (date1: Date, date2: Date): boolean => {
          return isSameWeek(date1, date2, { locale: de });
        };
        break;
      }
      case 'MONTH': {
        dateIsInBucket = isSameMonth;
        break;
      }
      case 'PARTIAL_WEEK': {
        dateIsInBucket = (date1: Date, date2: Date): boolean => {
          return isSameWeek(date1, date2, { locale: de }) && isSameMonth(date1, date2);
        };
        break;
      }
    }

    if (dateIsInBucket(startOfBucket, today)) {
      return {
        borderLeft: `1px dashed ${schaefflerColor}`,
      };
    }
    if (rltDate && dateIsInBucket(startOfBucket, rltDate)) {
      return {
        borderRight: `1px dashed ${warningColor}`,
      };
    }
    if (dateIsInBucket(startOfBucket, frozenZoneDate)) {
      return {
        borderRight: `1px dashed ${errorColor}`,
      };
    }
  };
