import { Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import React from 'react';
import styled, { css } from 'styled-components';

import { t } from '../../../core/i18n/i18n';
import { formatDate } from '../../../core/i18n/l10n';
import { ForecastInfo, MaterialListEntry } from '../../../domain/demandValidation/model';
import { grayBackgroundColor } from '../../../styles/colors';

export const SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES = [
  'C',
  'E',
  'F',
  'S',
  'U',
  'M',
  'N',
] as const;

export type SupplyConceptsStochasticType =
  (typeof SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES)[number];

function MoreInformation({
  forecastInfo,
  materialListEntity,
}: {
  forecastInfo: ForecastInfo | undefined;
  materialListEntity: MaterialListEntry | undefined;
}) {
  let supplyConcept = t('validation_of_demand.supply_concept.ELSE', {});
  const fixHor = materialListEntity?.fixHor ? formatDate(parseISO(materialListEntity?.fixHor)) : '';
  const stochasticType = materialListEntity?.stochasticType as SupplyConceptsStochasticType;

  // When the supply chain localization is changed, also check ExportDemandValidationService::buildSupplyConceptString
  // The logic is duplicated and must exist in frontend and backend
  if (SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES.includes(stochasticType)) {
    if (materialListEntity?.zv98QtyDl) {
      supplyConcept = t(`validation_of_demand.supply_concept.${stochasticType}.csss`, {
        fixHor: fixHor,
        safetyStock: materialListEntity?.zv98QtyDl,
      });
    } else if (materialListEntity?.eisbeDl) {
      supplyConcept = t(`validation_of_demand.supply_concept.${stochasticType}.ss`, {
        fixHor: fixHor,
        safetyStock: materialListEntity?.eisbeDl,
      });
    } else {
      supplyConcept = t(`validation_of_demand.supply_concept.${stochasticType}`, {
        fixHor: fixHor,
      });
    }
  }

  return (
    <MoreInformationSection $loading={!forecastInfo}>
      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.material_and_text', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.materialNumber || '-'} <br /> {forecastInfo?.materialDescription || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.classification', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.materialClassification || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.packaging_size', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.packagingSize || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.product_line_and_text', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.productLine || '-'} {forecastInfo?.productLineText || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {/*{t('validation_of_demand.more_information.product_line_and_text', {})}*/}
          Portfolio Status + Maintainable Dates
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.materialClassification} <br />
          {materialListEntity?.dateBeginMaintPossible} -{' '}
          {materialListEntity?.dateEndMaintPossible === '1900-01-01'
            ? '9999-12-31'
            : materialListEntity?.dateEndMaintPossible}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.production_line', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.productionLine || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.production_segment', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.productionSegment || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.current_wbz_schaeffler', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {forecastInfo?.currentRLTSchaeffler || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.supply_concept.title', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {supplyConcept}
        </Typography>
      </MoreInformationItem>
    </MoreInformationSection>
  );
}

const MoreInformationSection = styled.div<{ $loading: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  background-color: ${grayBackgroundColor};
  margin-top: 24px;
  padding: 20px;

  ${(props) =>
    props.$loading &&
    css`
      > * {
        opacity: 0;
      }
    `}
`;

const MoreInformationItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MoreInformation;
